<template>
  <div class="px-2 py-4">
    <h1 class="title">Area de superficie corporal - BSA</h1>
    <b-message type="is-info">
      Califique con el porcentaje que más se aproxime a su evaluación
    </b-message>

    <div class="block">
      <div class="columns is-multiline mt-2">
        <div class="column is-one-quarter">
          <b-field
            label="Cabeza y Cuello (max. 10)"
            :type="
              $v.answerOne.minValue == false || $v.answerOne.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerOne.minValue,
              },
              {
                'El valor máximo es 10.': !$v.answerOne.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="10"
              expanded
              v-model="answerOne"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
            </b-numberinput>
          </b-field>
        </div>
        <hr />
        <div class="column is-one-quarter">
          <b-field
            label="Extremidades superiores (max. 20)"
            :type="
              $v.answerTwo.minValue == false || $v.answerTwo.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerTwo.minValue,
              },
              {
                'El valor máximo es 20.': !$v.answerTwo.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="20"
              expanded
              v-model="answerTwo"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
            </b-numberinput>
          </b-field>
        </div>
        <hr />
        <div class="column is-one-quarter">
          <b-field
            label="Tronco (max. 30)"
            :type="
              $v.answerThree.minValue == false ||
              $v.answerThree.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerThree.minValue,
              },
              {
                'El valor máximo es 30.': !$v.answerThree.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="30"
              expanded
              v-model="answerThree"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
            </b-numberinput>
          </b-field>
        </div>
        <hr />
        <div class="column is-one-quarter">
          <b-field
            label="Extremidades inferiores (max. 40)"
            :type="
              $v.answerFour.minValue == false || $v.answerFour.maxValue == false
                ? 'is-danger'
                : 'is-ligth'
            "
            :message="[
              {
                'El valor mínimo es 0.': !$v.answerFour.minValue,
              },
              {
                'El valor máximo es 40.': !$v.answerFour.maxValue,
              },
            ]"
          >
            <b-numberinput
              size="is-small"
              controls-position="compact"
              controls-rounded
              :min="0"
              :max="40"
              expanded
              v-model="answerFour"
              @input="saveTermporalSulrvey"
              :disabled="queryMode"
            >
            </b-numberinput>
          </b-field>
        </div>
      </div>
    </div>

    <hr />

    <b-message type="is-info">
      <strong>Rango de resultados</strong>
      <br />
      {{ lowResult }}
      <br />
      Moderada a severa 5 – 10% BSA
      <br />
      Severa > 10% BSA
    </b-message>

    <div class="block has-text-centered title">
      Porcentaje de BSA/Resultado: {{ score }} ({{ scoreText }})
      <span v-if="isIncompleted">(Incompleto)</span>
    </div>

    <div class="buttons is-centered" v-if="!queryMode">
      <b-button class="clear-button" icon-right="block-helper" @click="clear"
        >Limpiar</b-button
      >
      <b-button class="save-button" icon-right="check-bold" @click="validate"
        >Guardar</b-button
      >
    </div>
  </div>
</template>

<script>
import { BSA as questions } from "../../../data/questions";
import { minValue, maxValue } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      questions: questions,
      answerOne: 0,
      answerTwo: 0,
      answerThree: 0,
      answerFour: 0,
      lowResult: "Leve < 5% BSA",
      restoreData: {},
      queryMode: false,
    };
  },
  async created() {
    if (!this.patient && !this.$route.params.surveyId) {
      this.$router.push({ name: "BsaList" }).then(() => {
        this.showToast("No has seleccionado un paciente.", "is-warning", 3000);
      });
    }

    if (this.$route.params.surveyId) {
      this.queryMode = true;
      let data = await this.getSurveyData();
      this.setEvaluationAnswers(data);
    }

    if (this.$route.params.temporalData) {
      this.answerOne = this.$route.params.temporalData.answerOne;
      this.answerTwo = this.$route.params.temporalData.answerTwo;
      this.answerThree = this.$route.params.temporalData.answerThree;
      this.answerFour = this.$route.params.temporalData.answerFour;
    }

    this.$global.$on("restore", () => {
      this.restoreForm();
    });
  },
  computed: {
    score() {
      return (
        parseInt(this.answerOne || 0) +
        parseInt(this.answerTwo || 0) +
        parseInt(this.answerThree || 0) +
        parseInt(this.answerFour || 0)
      );
    },
    scoreText() {
      let text = "";
      if (this.score <= 5) {
        text = "Leve";
      }

      if (this.score >= 6 && this.score <= 10) {
        text = "Moderada a severa";
      }

      if (this.score > 10) {
        text = "Severa";
      }
      return text;
    },
    isIncompleted() {
      return (
        !this.answerOne < 0 ||
        !this.answerTwo < 0 ||
        !this.answerThree < 0 ||
        !this.answerFour < 0
      );
    },
    userId: {
      get() {
        return this.$store.state.user.userData?.id;
      },
    },
    patient: {
      get() {
        return this.$store.state.patient.patientData;
      },
    },
  },
  methods: {
    cleanForms() {
      this.answerOne = 0;
      this.answerTwo = 0;
      this.answerThree = 0;
      this.answerFour = 0;
    },
    clear() {
      this.restoreData.answerOne = this.answerOne;
      this.restoreData.answerTwo = this.answerTwo;
      this.restoreData.answerThree = this.answerThree;
      this.restoreData.answerFour = this.answerFour;

      this.cleanForms();
      this.clearTemporalSurveys();
      this.showUndoDialog("Se limpiaron los datos de  la encuesta BSA.");
    },
    async save() {
      try {
        this.changeLoaderStatus(true);
        let data = {
          surveyCode: "BSA",
          result: String(this.score),
          appreciation: this.scoreText,
          doctorId: String(this.userId),
          patientId: String(this.patient.document),
          detail: this.setAnswers(),
        };

        await this.$surveyService.saveSurvey(data);
        this.changeLoaderStatus(false);
        this.showToast(
          "Encuesta enviada satisfactoriamente.",
          "is-success",
          3000
        );
        this.scrollTop();
        this.cleanForms();
        this.clearTemporalSurveys();
      } catch (error) {
        this.changeLoaderStatus(false);
        this.showToast("Ha ocurrido un error inesperado.", "is-danger", 3000);
      }
    },
    setAnswers() {
      let question1 = {
        questionCode: questions.BSA_CABEZA_CUELLO,
        answerCode: null,
        value: this.answerOne,
      };
      let question2 = {
        questionCode: questions.BSA_TRONCO,
        answerCode: null,
        value: this.answerTwo,
      };
      let question3 = {
        questionCode: questions.BSA_EXTREMIDADES_SUPERIORES,
        answerCode: null,
        value: this.answerThree,
      };

      let question4 = {
        questionCode: questions.BSA_EXTREMIDADES_INFERIORES,
        answerCode: null,
        value: this.answerFour,
      };
      return [question1, question2, question3, question4];
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.save();
      } else {
        this.showToast(
          "Hay valores erróneos en las respuestas, por favor corrígelos.",
          "is-warning",
          3000
        );
      }
    },
    restoreForm() {
      this.answerOne = this.restoreData.answerOne;
      this.answerTwo = this.restoreData.answerTwo;
      this.answerThree = this.restoreData.answerThree;
      this.answerFour = this.restoreData.answerFour;

      this.saveTermporalSulrvey();
    },
    saveTermporalSulrvey() {
      this.$session.set("temporalSurvey", {
        module: {
          name: "BSA",
          route: "Bsa",
        },
        survey: {
          answerOne: this.answerOne,
          answerTwo: this.answerTwo,
          answerThree: this.answerThree,
          answerFour: this.answerFour,
        },
      });
    },
    setEvaluationAnswers(data) {
      data.evaluationDetails.forEach((survey) => {
        if (survey.question.code == "BSA_CABEZA_CUELLO") {
          this.answerOne = parseInt(survey.value);
        }

        if (survey.question.code == "BSA_TRONCO") {
          this.answerTwo = parseInt(survey.value);
        }

        if (survey.question.code == "BSA_EXTREMIDADES_SUPERIORES") {
          this.answerThree = parseInt(survey.value);
        }

        if (survey.question.code == "BSA_EXTREMIDADES_INFERIORES") {
          this.answerFour = parseInt(survey.value);
        }
      });
    },
  },
  validations: {
    answerOne: {
      minValue: minValue(0),
      maxValue: maxValue(10),
    },
    answerTwo: {
      minValue: minValue(0),
      maxValue: maxValue(20),
    },
    answerThree: {
      minValue: minValue(0),
      maxValue: maxValue(30),
    },
    answerFour: {
      minValue: minValue(0),
      maxValue: maxValue(40),
    },
  },
};
</script>
