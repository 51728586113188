export const ACT = {
  ACT_P1: "ACT_P1",
  ACT_P2: "ACT_P2",
  ACT_P3: "ACT_P3",
  ACT_P4: "ACT_P4",
  ACT_P5: "ACT_P5",
};

export const POEM = {
  POEM_P1: "POEM_P1",
  POEM_P2: "POEM_P2",
  POEM_P3: "POEM_P3",
  POEM_P4: "POEM_P4",
  POEM_P5: "POEM_P5",
  POEM_P6: "POEM_P6",
  POEM_P7: "POEM_P7",
};

export const UCT = {
  UCT_P1: "UCT_P1",
  UCT_P2: "UCT_P2",
  UCT_P3: "UCT_P3",
  UCT_P4: "UCT_P4",
};

export const PGA = {
  PGA_P1: "PGA_P1",
  PGA_P2: "PGA_P2",
  PGA_P3: "PGA_P3",
};

export const CAT = {
  CAT_P1: "CAT_P1",
  CAT_P2: "CAT_P2",
  CAT_P3: "CAT_P3",
  CAT_P4: "CAT_P4",
  CAT_P5: "CAT_P5",
  CAT_P6: "CAT_P6",
  CAT_P7: "CAT_P7",
  CAT_P8: "CAT_P8",
};

export const DLQI = {
  DLQI_P1: "DLQI_P1",
  DLQI_P2: "DLQI_P2",
  DLQI_P3: "DLQI_P3",
  DLQI_P4: "DLQI_P4",
  DLQI_P5: "DLQI_P5",
  DLQI_P6: "DLQI_P6",
  DLQI_P7: "DLQI_P7",
  DLQI_P7A: "DLQI_P7A",
  DLQI_P8: "DLQI_P8",
  DLQI_P9: "DLQI_P9",
  DLQI_P10: "DLQI_P10",
};

export const WHOQOL_BREF = {
  WHOQOL_BREF_P1: "WHOQOL_BREF_P1",
  WHOQOL_BREF_P2: "WHOQOL_BREF_P2",
  WHOQOL_BREF_P3: "WHOQOL_BREF_P3",
  WHOQOL_BREF_P4: "WHOQOL_BREF_P4",
  WHOQOL_BREF_P5: "WHOQOL_BREF_P5",
  WHOQOL_BREF_P6: "WHOQOL_BREF_P6",
  WHOQOL_BREF_P7: "WHOQOL_BREF_P7",
  WHOQOL_BREF_P8: "WHOQOL_BREF_P8",
  WHOQOL_BREF_P9: "WHOQOL_BREF_P9",
  WHOQOL_BREF_P10: "WHOQOL_BREF_P10",
  WHOQOL_BREF_P11: "WHOQOL_BREF_P11",
  WHOQOL_BREF_P12: "WHOQOL_BREF_P12",
  WHOQOL_BREF_P13: "WHOQOL_BREF_P13",
  WHOQOL_BREF_P14: "WHOQOL_BREF_P14",
  WHOQOL_BREF_P15: "WHOQOL_BREF_P15",
  WHOQOL_BREF_P16: "WHOQOL_BREF_P16",
  WHOQOL_BREF_P17: "WHOQOL_BREF_P17",
  WHOQOL_BREF_P18: "WHOQOL_BREF_P18",
  WHOQOL_BREF_P19: "WHOQOL_BREF_P19",
  WHOQOL_BREF_P20: "WHOQOL_BREF_P20",
  WHOQOL_BREF_P21: "WHOQOL_BREF_P21",
  WHOQOL_BREF_P22: "WHOQOL_BREF_P22",
  WHOQOL_BREF_P23: "WHOQOL_BREF_P23",
  WHOQOL_BREF_P24: "WHOQOL_BREF_P24",
  WHOQOL_BREF_P25: "WHOQOL_BREF_P25",
  WHOQOL_BREF_P26: "WHOQOL_BREF_P26",
};

export const HUR = {
  HUR_P1: "HUR_P1",
  HUR_P2: "HUR_P2",
  HUR_P3: "HUR_P3",
  HUR_P4: "HUR_P4",
  HUR_P5: "HUR_P5",
};

export const RIE_ASO = {
  RIE_ASO_P1: "RIE_ASO_P1",
  RIE_ASO_P2: "RIE_ASO_P2",
  RIE_ASO_P3: "RIE_ASO_P3",
  RIE_ASO_P4: "RIE_ASO_P4",
  RIE_ASO_P5: "RIE_ASO_P5",
  RIE_ASO_P6: "RIE_ASO_P6",
  RIE_ASO_P7: "RIE_ASO_P7",
  RIE_ASO_P8: "RIE_ASO_P8",
  RIE_ASO_P9: "RIE_ASO_P9",
  RIE_ASO_P10: "RIE_ASO_P10",
  RIE_ASO_P11: "RIE_ASO_P11",
  RIE_ASO_P12: "RIE_ASO_P12",
  RIE_ASO_P13: "RIE_ASO_P13",
  RIE_ASO_P14: "RIE_ASO_P14",
  RIE_ASO_P15: "RIE_ASO_P15",
  RIE_ASO_P16: "RIE_ASO_P16",
};

export const TOPAS = {
  TOPAS_P1: "TOPAS_P1",
  TOPAS_P1_A: "TOPAS_P1_A",
  TOPAS_P1_B: "TOPAS_P1_B",
  TOPAS_P2_A: "TOPAS_P2_A",
  TOPAS_P2_B: "TOPAS_P2_B",
  TOPAS_P2_B_A: "TOPAS_P2_B_A",
  TOPAS_P2_B_B: "TOPAS_P2_B_B",
  TOPAS_P3: "TOPAS_P3",
  TOPAS_P4: "TOPAS_P4",
  TOPAS_P4_A: "TOPAS_P4_A",
  TOPAS_P5: "TOPAS_P5",
  TOPAS_P5_A: "TOPAS_P5_A",
  TOPAS_P5_B: "TOPAS_P5_B",
  TOPAS_P6: "TOPAS_P6",
  TOPAS_P7: "TOPAS_P7",
  TOPAS_P7_A: "TOPAS_P7_A",
  TOPAS_P7_B: "TOPAS_P7_B",
  TOPAS_P8: "TOPAS_P8",
  TOPAS_P8_A: "TOPAS_P8_A",
  TOPAS_P8_B: "TOPAS_P8_B",
  TOPAS_P9: "TOPAS_P9",
  TOPAS_P9_A: "TOPAS_P9_A",
  TOPAS_P9_B: "TOPAS_P9_B",
  TOPAS_P10: "TOPAS_P10",
  TOPAS_P11: "TOPAS_P11",
  TOPAS_P11A: "TOPAS_P11_ARTRITIS",
  TOPAS_P11B: "TOPAS_P11_ESCLERODERMA",
  TOPAS_P11C: "TOPAS_P11_ESPONDILITIS",
  TOPAS_P11D: "TOPAS_P11_FIBROMIAGLIA",
  TOPAS_P11E: "TOPAS_P11_LUPUS",
  TOPAS_P11F: "TOPAS_P11_OSTEOARTRITIS",
  TOPAS_P11G: "TOPAS_P11_OTRO",
  TOPAS_P12: "TOPAS_P12",
  TOPAS_P12_A: "TOPAS_P12_A",
};

export const EASI = {
  HEAD: {
    EASI_HEAD_AREA: "EASI_HEAD_AREA",
    EASI_HEAD_ERITEMA: "EASI_HEAD_ERITEMA",
    EASI_HEAD_EDEMA: "EASI_HEAD_EDEMA",
    EASI_HEAD_EXCORIACION: "EASI_HEAD_EXCORIACION",
    EASI_HEAD_LIQUENIFICACION: "EASI_HEAD_LIQUENIFICACION",
  },
  TRUK: {
    EASI_TRUK_AREA: "EASI_TRUK_AREA",
    EASI_TRUK_ERITEMA: "EASI_TRUK_ERITEMA",
    EASI_TRUK_EDEMA: "EASI_TRUK_EDEMA",
    EASI_TRUK_EXCORIACION: "EASI_TRUK_EXCORIACION",
    EASI_TRUK_LIQUENIFICACION: "EASI_TRUK_LIQUENIFICACION",
  },
  SUPERIOR_LIMBS: {
    EASI_SUPERIOR_LIMBS_AREA: "EASI_SUPERIOR_LIMBS_AREA",
    EASI_SUPERIOR_LIMBS_ERITEMA: "EASI_SUPERIOR_LIMBS_ERITEMA",
    EASI_SUPERIOR_LIMBS_EDEMA: "EASI_SUPERIOR_LIMBS_EDEMA",
    EASI_SUPERIOR_LIMBS_EXCORIACION: "EASI_SUPERIOR_LIMBS_EXCORIACION",
    EASI_SUPERIOR_LIMBS_LIQUENIFICACION: "EASI_SUPERIOR_LIMBS_LIQUENIFICACION",
  },
  LOWER_LIMBS: {
    EASI_LOWER_LIMBS_AREA: "EASI_LOWER_LIMBS_AREA",
    EASI_LOWER_LIMBS_ERITEMA: "EASI_LOWER_LIMBS_ERITEMA",
    EASI_LOWER_LIMBS_EDEMA: "EASI_LOWER_LIMBS_EDEMA",
    EASI_LOWER_LIMBS_EXCORIACION: "EASI_LOWER_LIMBS_EXCORIACION",
    EASI_LOWER_LIMBS_LIQUENIFICACION: "EASI_LOWER_LIMBS_LIQUENIFICACION",
  },
};

export const PASI = {
  HEAD: {
    PASI_HEAD_AREA: "PASI_HEAD_AREA",
    PASI_HEAD_PICOR: "PASI_HEAD_PICOR",
    PASI_HEAD_ERITEMA: "PASI_HEAD_ERITEMA",
    PASI_HEAD_DESCAMACION: "PASI_HEAD_DESCAMACION",
    PASI_HEAD_INDURACION: "PASI_HEAD_INDURACION",
  },
  TRUK: {
    PASI_TRUK_AREA: "PASI_TRUK_AREA",
    PASI_TRUK_PICOR: "PASI_TRUK_PICOR",
    PASI_TRUK_ERITEMA: "PASI_TRUK_ERITEMA",
    PASI_TRUK_DESCAMACION: "PASI_TRUK_DESCAMACION",
    PASI_TRUK_INDURACION: "PASI_TRUK_INDURACION",
  },
  SUPERIOR_LIMBS: {
    PASI_SUPERIOR_LIMBS_AREA: "PASI_SUPERIOR_LIMBS_AREA",
    PASI_SUPERIOR_LIMBS_PICOR: "PASI_SUPERIOR_LIMBS_PICOR",
    PASI_SUPERIOR_LIMBS_ERITEMA: "PASI_SUPERIOR_LIMBS_ERITEMA",
    PASI_SUPERIOR_LIMBS_DESCAMACION: "PASI_SUPERIOR_LIMBS_DESCAMACION",
    PASI_SUPERIOR_LIMBS_INDURACION: "PASI_SUPERIOR_LIMBS_INDURACION",
  },
  LOWER_LIMBS: {
    PASI_LOWER_LIMBS_AREA: "PASI_LOWER_LIMBS_AREA",
    PASI_LOWER_LIMBS_PICOR: "PASI_LOWER_LIMBS_PICOR",
    PASI_LOWER_LIMBS_ERITEMA: "PASI_LOWER_LIMBS_ERITEMA",
    PASI_LOWER_LIMBS_DESCAMACION: "PASI_LOWER_LIMBS_DESCAMACION",
    PASI_LOWER_LIMBS_INDURACION: "PASI_LOWER_LIMBS_INDURACION",
  },
};

export const UAS7 = {
  SEMANA1: {
    DIA1: {
      UAS7_SEMANA_1_DIA1_RONCHAS: "UAS7_SEMANA_1_DIA1_RONCHAS",
      UAS7_SEMANA_1_DIA1_PICAZON: "UAS7_SEMANA_1_DIA1_PICAZON",
    },
    DIA2: {
      UAS7_SEMANA_1_DIA2_RONCHAS: "UAS7_SEMANA_1_DIA2_RONCHAS",
      UAS7_SEMANA_1_DIA2_PICAZON: "UAS7_SEMANA_1_DIA2_PICAZON",
    },
    DIA3: {
      UAS7_SEMANA_1_DIA3_RONCHAS: "UAS7_SEMANA_1_DIA3_RONCHAS",
      UAS7_SEMANA_1_DIA3_PICAZON: "UAS7_SEMANA_1_DIA3_PICAZON",
    },
    DIA4: {
      UAS7_SEMANA_1_DIA4_RONCHAS: "UAS7_SEMANA_1_DIA4_RONCHAS",
      UAS7_SEMANA_1_DIA4_PICAZON: "UAS7_SEMANA_1_DIA4_PICAZON",
    },
    DIA5: {
      UAS7_SEMANA_1_DIA5_RONCHAS: "UAS7_SEMANA_1_DIA5_RONCHAS",
      UAS7_SEMANA_1_DIA5_PICAZON: "UAS7_SEMANA_1_DIA5_PICAZON",
    },
    DIA6: {
      UAS7_SEMANA_1_DIA6_RONCHAS: "UAS7_SEMANA_1_DIA6_RONCHAS",
      UAS7_SEMANA_1_DIA6_PICAZON: "UAS7_SEMANA_1_DIA6_PICAZON",
    },
    DIA7: {
      UAS7_SEMANA_1_DIA7_RONCHAS: "UAS7_SEMANA_1_DIA7_RONCHAS",
      UAS7_SEMANA_1_DIA7_PICAZON: "UAS7_SEMANA_1_DIA7_PICAZON",
    },
  },
  SEMANA2: {
    DIA1: {
      UAS7_SEMANA_2_DIA1_RONCHAS: "UAS7_SEMANA_2_DIA1_RONCHAS",
      UAS7_SEMANA_2_DIA1_PICAZON: "UAS7_SEMANA_2_DIA1_PICAZON",
    },
    DIA2: {
      UAS7_SEMANA_2_DIA2_RONCHAS: "UAS7_SEMANA_2_DIA2_RONCHAS",
      UAS7_SEMANA_2_DIA2_PICAZON: "UAS7_SEMANA_2_DIA2_PICAZON",
    },
    DIA3: {
      UAS7_SEMANA_2_DIA3_RONCHAS: "UAS7_SEMANA_2_DIA3_RONCHAS",
      UAS7_SEMANA_2_DIA3_PICAZON: "UAS7_SEMANA_2_DIA3_PICAZON",
    },
    DIA4: {
      UAS7_SEMANA_2_DIA4_RONCHAS: "UAS7_SEMANA_2_DIA4_RONCHAS",
      UAS7_SEMANA_2_DIA4_PICAZON: "UAS7_SEMANA_2_DIA4_PICAZON",
    },
    DIA5: {
      UAS7_SEMANA_2_DIA5_RONCHAS: "UAS7_SEMANA_2_DIA5_RONCHAS",
      UAS7_SEMANA_2_DIA5_PICAZON: "UAS7_SEMANA_2_DIA5_PICAZON",
    },
    DIA6: {
      UAS7_SEMANA_2_DIA6_RONCHAS: "UAS7_SEMANA_2_DIA6_RONCHAS",
      UAS7_SEMANA_2_DIA6_PICAZON: "UAS7_SEMANA_2_DIA6_PICAZON",
    },
    DIA7: {
      UAS7_SEMANA_2_DIA7_RONCHAS: "UAS7_SEMANA_2_DIA7_RONCHAS",
      UAS7_SEMANA_2_DIA7_PICAZON: "UAS7_SEMANA_2_DIA7_PICAZON",
    },
  },
  SEMANA3: {
    DIA1: {
      UAS7_SEMANA_3_DIA1_RONCHAS: "UAS7_SEMANA_3_DIA1_RONCHAS",
      UAS7_SEMANA_3_DIA1_PICAZON: "UAS7_SEMANA_3_DIA1_PICAZON",
    },
    DIA2: {
      UAS7_SEMANA_3_DIA2_RONCHAS: "UAS7_SEMANA_3_DIA2_RONCHAS",
      UAS7_SEMANA_3_DIA2_PICAZON: "UAS7_SEMANA_3_DIA2_PICAZON",
    },
    DIA3: {
      UAS7_SEMANA_3_DIA3_RONCHAS: "UAS7_SEMANA_3_DIA3_RONCHAS",
      UAS7_SEMANA_3_DIA3_PICAZON: "UAS7_SEMANA_3_DIA3_PICAZON",
    },
    DIA4: {
      UAS7_SEMANA_3_DIA4_RONCHAS: "UAS7_SEMANA_3_DIA4_RONCHAS",
      UAS7_SEMANA_3_DIA4_PICAZON: "UAS7_SEMANA_3_DIA4_PICAZON",
    },
    DIA5: {
      UAS7_SEMANA_3_DIA5_RONCHAS: "UAS7_SEMANA_3_DIA5_RONCHAS",
      UAS7_SEMANA_3_DIA5_PICAZON: "UAS7_SEMANA_3_DIA5_PICAZON",
    },
    DIA6: {
      UAS7_SEMANA_3_DIA6_RONCHAS: "UAS7_SEMANA_3_DIA6_RONCHAS",
      UAS7_SEMANA_3_DIA6_PICAZON: "UAS7_SEMANA_3_DIA6_PICAZON",
    },
    DIA7: {
      UAS7_SEMANA_3_DIA7_RONCHAS: "UAS7_SEMANA_3_DIA7_RONCHAS",
      UAS7_SEMANA_3_DIA7_PICAZON: "UAS7_SEMANA_3_DIA7_PICAZON",
    },
  },
  SEMANA4: {
    DIA1: {
      UAS7_SEMANA_4_DIA1_RONCHAS: "UAS7_SEMANA_4_DIA1_RONCHAS",
      UAS7_SEMANA_4_DIA1_PICAZON: "UAS7_SEMANA_4_DIA1_PICAZON",
    },
    DIA2: {
      UAS7_SEMANA_4_DIA2_RONCHAS: "UAS7_SEMANA_4_DIA2_RONCHAS",
      UAS7_SEMANA_4_DIA2_PICAZON: "UAS7_SEMANA_4_DIA2_PICAZON",
    },
    DIA3: {
      UAS7_SEMANA_4_DIA3_RONCHAS: "UAS7_SEMANA_4_DIA3_RONCHAS",
      UAS7_SEMANA_4_DIA3_PICAZON: "UAS7_SEMANA_4_DIA3_PICAZON",
    },
    DIA4: {
      UAS7_SEMANA_4_DIA4_RONCHAS: "UAS7_SEMANA_4_DIA4_RONCHAS",
      UAS7_SEMANA_4_DIA4_PICAZON: "UAS7_SEMANA_4_DIA4_PICAZON",
    },
    DIA5: {
      UAS7_SEMANA_4_DIA5_RONCHAS: "UAS7_SEMANA_4_DIA5_RONCHAS",
      UAS7_SEMANA_4_DIA5_PICAZON: "UAS7_SEMANA_4_DIA5_PICAZON",
    },
    DIA6: {
      UAS7_SEMANA_4_DIA6_RONCHAS: "UAS7_SEMANA_4_DIA6_RONCHAS",
      UAS7_SEMANA_4_DIA6_PICAZON: "UAS7_SEMANA_4_DIA6_PICAZON",
    },
    DIA7: {
      UAS7_SEMANA_4_DIA7_RONCHAS: "UAS7_SEMANA_4_DIA7_RONCHAS",
      UAS7_SEMANA_4_DIA7_PICAZON: "UAS7_SEMANA_4_DIA7_PICAZON",
    },
  },
};

export const BSA = {
  BSA_CABEZA_CUELLO: "BSA_CABEZA_CUELLO",
  BSA_TRONCO: "BSA_TRONCO",
  BSA_EXTREMIDADES_SUPERIORES: "BSA_EXTREMIDADES_SUPERIORES",
  BSA_EXTREMIDADES_INFERIORES: "BSA_EXTREMIDADES_INFERIORES",
};

export const HIS4 = {
  HIS4_NODULOS: "HIS4_NODULOS",
  HIS4_ABSESOS: "HIS4_ABSESOS",
  HIS4_FISTULAS: "HIS4_FISTULAS",
};

export const NAPSI = {
  MANOS: {
    MATRIX: {
      NAPSI_MANOS_DEDO1_MATRIX: "NAPSI_MANOS_DEDO1_MATRIX",
      NAPSI_MANOS_DEDO2_MATRIX: "NAPSI_MANOS_DEDO2_MATRIX",
      NAPSI_MANOS_DEDO3_MATRIX: "NAPSI_MANOS_DEDO3_MATRIX",
      NAPSI_MANOS_DEDO4_MATRIX: "NAPSI_MANOS_DEDO4_MATRIX",
      NAPSI_MANOS_DEDO5_MATRIX: "NAPSI_MANOS_DEDO5_MATRIX",
      NAPSI_MANOS_DEDO6_MATRIX: "NAPSI_MANOS_DEDO6_MATRIX",
      NAPSI_MANOS_DEDO7_MATRIX: "NAPSI_MANOS_DEDO7_MATRIX",
      NAPSI_MANOS_DEDO8_MATRIX: "NAPSI_MANOS_DEDO8_MATRIX",
      NAPSI_MANOS_DEDO9_MATRIX: "NAPSI_MANOS_DEDO9_MATRIX",
      NAPSI_MANOS_DEDO10_MATRIX: "NAPSI_MANOS_DEDO10_MATRIX",
    },
    LECHO: {
      NAPSI_MANOS_DEDO1_LECHO: "NAPSI_MANOS_DEDO1_LECHO",
      NAPSI_MANOS_DEDO2_LECHO: "NAPSI_MANOS_DEDO2_LECHO",
      NAPSI_MANOS_DEDO3_LECHO: "NAPSI_MANOS_DEDO3_LECHO",
      NAPSI_MANOS_DEDO4_LECHO: "NAPSI_MANOS_DEDO4_LECHO",
      NAPSI_MANOS_DEDO5_LECHO: "NAPSI_MANOS_DEDO5_LECHO",
      NAPSI_MANOS_DEDO6_LECHO: "NAPSI_MANOS_DEDO6_LECHO",
      NAPSI_MANOS_DEDO7_LECHO: "NAPSI_MANOS_DEDO7_LECHO",
      NAPSI_MANOS_DEDO8_LECHO: "NAPSI_MANOS_DEDO8_LECHO",
      NAPSI_MANOS_DEDO9_LECHO: "NAPSI_MANOS_DEDO9_LECHO",
      NAPSI_MANOS_DEDO10_LECHO: "NAPSI_MANOS_DEDO10_LECHO",
    },
  },
  PIES: {
    MATRIX: {
      NAPSI_PIES_DEDO1_MATRIX: "NAPSI_PIES_DEDO1_MATRIX",
      NAPSI_PIES_DEDO2_MATRIX: "NAPSI_PIES_DEDO2_MATRIX",
      NAPSI_PIES_DEDO3_MATRIX: "NAPSI_PIES_DEDO3_MATRIX",
      NAPSI_PIES_DEDO4_MATRIX: "NAPSI_PIES_DEDO4_MATRIX",
      NAPSI_PIES_DEDO5_MATRIX: "NAPSI_PIES_DEDO5_MATRIX",
      NAPSI_PIES_DEDO6_MATRIX: "NAPSI_PIES_DEDO6_MATRIX",
      NAPSI_PIES_DEDO7_MATRIX: "NAPSI_PIES_DEDO7_MATRIX",
      NAPSI_PIES_DEDO8_MATRIX: "NAPSI_PIES_DEDO8_MATRIX",
      NAPSI_PIES_DEDO9_MATRIX: "NAPSI_PIES_DEDO9_MATRIX",
      NAPSI_PIES_DEDO10_MATRIX: "NAPSI_PIES_DEDO10_MATRIX",
    },
    LECHO: {
      NAPSI_PIES_DEDO1_LECHO: "NAPSI_PIES_DEDO1_LECHO",
      NAPSI_PIES_DEDO2_LECHO: "NAPSI_PIES_DEDO2_LECHO",
      NAPSI_PIES_DEDO3_LECHO: "NAPSI_PIES_DEDO3_LECHO",
      NAPSI_PIES_DEDO4_LECHO: "NAPSI_PIES_DEDO4_LECHO",
      NAPSI_PIES_DEDO5_LECHO: "NAPSI_PIES_DEDO5_LECHO",
      NAPSI_PIES_DEDO6_LECHO: "NAPSI_PIES_DEDO6_LECHO",
      NAPSI_PIES_DEDO7_LECHO: "NAPSI_PIES_DEDO7_LECHO",
      NAPSI_PIES_DEDO8_LECHO: "NAPSI_PIES_DEDO8_LECHO",
      NAPSI_PIES_DEDO9_LECHO: "NAPSI_PIES_DEDO9_LECHO",
      NAPSI_PIES_DEDO10_LECHO: "NAPSI_PIES_DEDO10_LECHO",
    },
  },
};

export const NAPSIMM = {
  ONICOLISIS: {
    NAPSIMM_DEDO1_ONICOLISIS: "NAPSIMM_DEDO1_ONICOLISIS",
    NAPSIMM_DEDO2_ONICOLISIS: "NAPSIMM_DEDO2_ONICOLISIS",
    NAPSIMM_DEDO3_ONICOLISIS: "NAPSIMM_DEDO3_ONICOLISIS",
    NAPSIMM_DEDO4_ONICOLISIS: "NAPSIMM_DEDO4_ONICOLISIS",
    NAPSIMM_DEDO5_ONICOLISIS: "NAPSIMM_DEDO5_ONICOLISIS",
    NAPSIMM_DEDO6_ONICOLISIS: "NAPSIMM_DEDO6_ONICOLISIS",
    NAPSIMM_DEDO7_ONICOLISIS: "NAPSIMM_DEDO7_ONICOLISIS",
    NAPSIMM_DEDO8_ONICOLISIS: "NAPSIMM_DEDO8_ONICOLISIS",
    NAPSIMM_DEDO9_ONICOLISIS: "NAPSIMM_DEDO9_ONICOLISIS",
    NAPSIMM_DEDO10_ONICOLISIS: "NAPSIMM_DEDO10_ONICOLISIS",
  },
  PITTS: {
    NAPSIMM_DEDO1_PITTS: "NAPSIMM_DEDO1_PITTS",
    NAPSIMM_DEDO2_PITTS: "NAPSIMM_DEDO2_PITTS",
    NAPSIMM_DEDO3_PITTS: "NAPSIMM_DEDO3_PITTS",
    NAPSIMM_DEDO4_PITTS: "NAPSIMM_DEDO4_PITTS",
    NAPSIMM_DEDO5_PITTS: "NAPSIMM_DEDO5_PITTS",
    NAPSIMM_DEDO6_PITTS: "NAPSIMM_DEDO6_PITTS",
    NAPSIMM_DEDO7_PITTS: "NAPSIMM_DEDO7_PITTS",
    NAPSIMM_DEDO8_PITTS: "NAPSIMM_DEDO8_PITTS",
    NAPSIMM_DEDO9_PITTS: "NAPSIMM_DEDO9_PITTS",
    NAPSIMM_DEDO10_PITTS: "NAPSIMM_DEDO10_PITTS",
  },
  ONICODISTROFIA: {
    NAPSIMM_DEDO1_ONICODISTROFIA: "NAPSIMM_DEDO1_ONICODISTROFIA",
    NAPSIMM_DEDO2_ONICODISTROFIA: "NAPSIMM_DEDO2_ONICODISTROFIA",
    NAPSIMM_DEDO3_ONICODISTROFIA: "NAPSIMM_DEDO3_ONICODISTROFIA",
    NAPSIMM_DEDO4_ONICODISTROFIA: "NAPSIMM_DEDO4_ONICODISTROFIA",
    NAPSIMM_DEDO5_ONICODISTROFIA: "NAPSIMM_DEDO5_ONICODISTROFIA",
    NAPSIMM_DEDO6_ONICODISTROFIA: "NAPSIMM_DEDO6_ONICODISTROFIA",
    NAPSIMM_DEDO7_ONICODISTROFIA: "NAPSIMM_DEDO7_ONICODISTROFIA",
    NAPSIMM_DEDO8_ONICODISTROFIA: "NAPSIMM_DEDO8_ONICODISTROFIA",
    NAPSIMM_DEDO9_ONICODISTROFIA: "NAPSIMM_DEDO9_ONICODISTROFIA",
    NAPSIMM_DEDO10_ONICODISTROFIA: "NAPSIMM_DEDO10_ONICODISTROFIA",
  },
  LEUCONIQUIA: {
    NAPSIMM_DEDO1_LEUCONIQUIA: "NAPSIMM_DEDO1_LEUCONIQUIA",
    NAPSIMM_DEDO2_LEUCONIQUIA: "NAPSIMM_DEDO2_LEUCONIQUIA",
    NAPSIMM_DEDO3_LEUCONIQUIA: "NAPSIMM_DEDO3_LEUCONIQUIA",
    NAPSIMM_DEDO4_LEUCONIQUIA: "NAPSIMM_DEDO4_LEUCONIQUIA",
    NAPSIMM_DEDO5_LEUCONIQUIA: "NAPSIMM_DEDO5_LEUCONIQUIA",
    NAPSIMM_DEDO6_LEUCONIQUIA: "NAPSIMM_DEDO6_LEUCONIQUIA",
    NAPSIMM_DEDO7_LEUCONIQUIA: "NAPSIMM_DEDO7_LEUCONIQUIA",
    NAPSIMM_DEDO8_LEUCONIQUIA: "NAPSIMM_DEDO8_LEUCONIQUIA",
    NAPSIMM_DEDO9_LEUCONIQUIA: "NAPSIMM_DEDO9_LEUCONIQUIA",
    NAPSIMM_DEDO10_LEUCONIQUIA: "NAPSIMM_DEDO10_LEUCONIQUIA",
  },
  HEMORRAGIAS: {
    NAPSIMM_DEDO1_HEMORRAGIAS: "NAPSIMM_DEDO1_HEMORRAGIAS",
    NAPSIMM_DEDO2_HEMORRAGIAS: "NAPSIMM_DEDO2_HEMORRAGIAS",
    NAPSIMM_DEDO3_HEMORRAGIAS: "NAPSIMM_DEDO3_HEMORRAGIAS",
    NAPSIMM_DEDO4_HEMORRAGIAS: "NAPSIMM_DEDO4_HEMORRAGIAS",
    NAPSIMM_DEDO5_HEMORRAGIAS: "NAPSIMM_DEDO5_HEMORRAGIAS",
    NAPSIMM_DEDO6_HEMORRAGIAS: "NAPSIMM_DEDO6_HEMORRAGIAS",
    NAPSIMM_DEDO7_HEMORRAGIAS: "NAPSIMM_DEDO7_HEMORRAGIAS",
    NAPSIMM_DEDO8_HEMORRAGIAS: "NAPSIMM_DEDO8_HEMORRAGIAS",
    NAPSIMM_DEDO9_HEMORRAGIAS: "NAPSIMM_DEDO9_HEMORRAGIAS",
    NAPSIMM_DEDO10_HEMORRAGIAS: "NAPSIMM_DEDO10_HEMORRAGIAS",
  },
  HIPERQUERATOSIS: {
    NAPSIMM_DEDO1_HIPERQUERATOSIS: "NAPSIMM_DEDO1_HIPERQUERATOSIS",
    NAPSIMM_DEDO2_HIPERQUERATOSIS: "NAPSIMM_DEDO2_HIPERQUERATOSIS",
    NAPSIMM_DEDO3_HIPERQUERATOSIS: "NAPSIMM_DEDO3_HIPERQUERATOSIS",
    NAPSIMM_DEDO4_HIPERQUERATOSIS: "NAPSIMM_DEDO4_HIPERQUERATOSIS",
    NAPSIMM_DEDO5_HIPERQUERATOSIS: "NAPSIMM_DEDO5_HIPERQUERATOSIS",
    NAPSIMM_DEDO6_HIPERQUERATOSIS: "NAPSIMM_DEDO6_HIPERQUERATOSIS",
    NAPSIMM_DEDO7_HIPERQUERATOSIS: "NAPSIMM_DEDO7_HIPERQUERATOSIS",
    NAPSIMM_DEDO8_HIPERQUERATOSIS: "NAPSIMM_DEDO8_HIPERQUERATOSIS",
    NAPSIMM_DEDO9_HIPERQUERATOSIS: "NAPSIMM_DEDO9_HIPERQUERATOSIS",
    NAPSIMM_DEDO10_HIPERQUERATOSIS: "NAPSIMM_DEDO10_HIPERQUERATOSIS",
  },
  MANCHAS: {
    NAPSIMM_DEDO1_MANCHAS: "NAPSIMM_DEDO1_MANCHAS",
    NAPSIMM_DEDO2_MANCHAS: "NAPSIMM_DEDO2_MANCHAS",
    NAPSIMM_DEDO3_MANCHAS: "NAPSIMM_DEDO3_MANCHAS",
    NAPSIMM_DEDO4_MANCHAS: "NAPSIMM_DEDO4_MANCHAS",
    NAPSIMM_DEDO5_MANCHAS: "NAPSIMM_DEDO5_MANCHAS",
    NAPSIMM_DEDO6_MANCHAS: "NAPSIMM_DEDO6_MANCHAS",
    NAPSIMM_DEDO7_MANCHAS: "NAPSIMM_DEDO7_MANCHAS",
    NAPSIMM_DEDO8_MANCHAS: "NAPSIMM_DEDO8_MANCHAS",
    NAPSIMM_DEDO9_MANCHAS: "NAPSIMM_DEDO9_MANCHAS",
    NAPSIMM_DEDO10_MANCHAS: "NAPSIMM_DEDO10_MANCHAS",
  },
};

export const SCORAD = {
  ZONA: {
    SCORAD_ZONA_CABEZA_CUELLO: "SCORAD_ZONA_CABEZA_CUELLO",
    SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA:
      "SCORAD_ZONA_EXTREMIDADES_SUPERIORES_IZQUIERDA",
    SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA:
      "SCORAD_ZONA_EXTREMIDADES_SUPERIORES_DERECHA",
    SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA:
      "SCORAD_ZONA_MIEMBROS_INFERIORES_IZQUIERDA",
    SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA:
      "SCORAD_ZONA_MIEMBROS_INFERIORES_DERECHA",
    SCORAD_ZONA_TRONCO_ANTERIOR: "SCORAD_ZONA_TRONCO_ANTERIOR",
    SCORAD_ZONA_ESPALDA: "SCORAD_ZONA_ESPALDA",
    SCORAD_ZONA_GENITALES: "SCORAD_ZONA_GENITALES",
  },
  SIGNOS: {
    SCORAD_SIGNOS_ROJEZ: "SCORAD_SIGNOS_ROJEZ",
    SCORAD_SIGNOS_HINCHAZON: "SCORAD_SIGNOS_HINCHAZON",
    SCORAD_SIGNOS_COSTRAS: "SCORAD_SIGNOS_COSTRAS",
    SCORAD_SIGNOS_MARCAS: "SCORAD_SIGNOS_MARCAS",
    SCORAD_SIGNOS_LIQUENIFICACION: "SCORAD_SIGNOS_LIQUENIFICACION",
    SCORAD_SIGNOS_SEQUEDAD: "SCORAD_SIGNOS_SEQUEDAD",
  },
  SINTOMAS: {
    SCORAD_SINTOMAS_PRURITO: "SCORAD_SINTOMAS_PRURITO",
    SCORAD_SINTOMAS_INSOMNIO: "SCORAD_SINTOMAS_INSOMNIO",
  },
};

export const ADCT = {
  ADCT_P1: "ADCT_P1",
  ADCT_P2: "ADCT_P2",
  ADCT_P3: "ADCT_P3",
  ADCT_P4: "ADCT_P4",
  ADCT_P5: "ADCT_P5",
  ADCT_P6: "ADCT_P6",
};


export const CDLQI = {
  CDLQI_P1: "CDLQI_P1",
  CDLQI_P2: "CDLQI_P2",
  CDLQI_P3: "CDLQI_P3",
  CDLQI_P4: "CDLQI_P4",
  CDLQI_P5: "CDLQI_P5",
  CDLQI_P6: "CDLQI_P6",
  CDLQI_P7: "CDLQI_P7",
  CDLQI_P7A: "CDLQI_P7A",
  CDLQI_P7B: "CDLQI_P7B",
  CDLQI_P8: "CDLQI_P8",
  CDLQI_P9: "CDLQI_P9",
  CDLQI_P10: "CDLQI_P10",
};


export const IDQOL = {
  IDQOL_P1: "IDQOL_P1",
  IDQOL_P2: "IDQOL_P2",
  IDQOL_P3: "IDQOL_P3",
  IDQOL_P4: "IDQOL_P4",
  IDQOL_P5: "IDQOL_P5",
  IDQOL_P6: "IDQOL_P6",
  IDQOL_P7: "IDQOL_P7",
  IDQOL_P8: "IDQOL_P8",
  IDQOL_P9: "IDQOL_P9",
  IDQOL_P10: "IDQOL_P10",
};
