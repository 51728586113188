var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 py-4"},[_c('h1',{staticClass:"title"},[_vm._v("Area de superficie corporal - BSA")]),_c('b-message',{attrs:{"type":"is-info"}},[_vm._v(" Califique con el porcentaje que más se aproxime a su evaluación ")]),_c('div',{staticClass:"block"},[_c('div',{staticClass:"columns is-multiline mt-2"},[_c('div',{staticClass:"column is-one-quarter"},[_c('b-field',{attrs:{"label":"Cabeza y Cuello (max. 10)","type":_vm.$v.answerOne.minValue == false || _vm.$v.answerOne.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerOne.minValue,
            },
            {
              'El valor máximo es 10.': !_vm.$v.answerOne.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":10,"expanded":"","disabled":_vm.queryMode},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerOne),callback:function ($$v) {_vm.answerOne=$$v},expression:"answerOne"}})],1)],1),_c('hr'),_c('div',{staticClass:"column is-one-quarter"},[_c('b-field',{attrs:{"label":"Extremidades superiores (max. 20)","type":_vm.$v.answerTwo.minValue == false || _vm.$v.answerTwo.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerTwo.minValue,
            },
            {
              'El valor máximo es 20.': !_vm.$v.answerTwo.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":20,"expanded":"","disabled":_vm.queryMode},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerTwo),callback:function ($$v) {_vm.answerTwo=$$v},expression:"answerTwo"}})],1)],1),_c('hr'),_c('div',{staticClass:"column is-one-quarter"},[_c('b-field',{attrs:{"label":"Tronco (max. 30)","type":_vm.$v.answerThree.minValue == false ||
            _vm.$v.answerThree.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerThree.minValue,
            },
            {
              'El valor máximo es 30.': !_vm.$v.answerThree.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":30,"expanded":"","disabled":_vm.queryMode},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerThree),callback:function ($$v) {_vm.answerThree=$$v},expression:"answerThree"}})],1)],1),_c('hr'),_c('div',{staticClass:"column is-one-quarter"},[_c('b-field',{attrs:{"label":"Extremidades inferiores (max. 40)","type":_vm.$v.answerFour.minValue == false || _vm.$v.answerFour.maxValue == false
              ? 'is-danger'
              : 'is-ligth',"message":[
            {
              'El valor mínimo es 0.': !_vm.$v.answerFour.minValue,
            },
            {
              'El valor máximo es 40.': !_vm.$v.answerFour.maxValue,
            } ]}},[_c('b-numberinput',{attrs:{"size":"is-small","controls-position":"compact","controls-rounded":"","min":0,"max":40,"expanded":"","disabled":_vm.queryMode},on:{"input":_vm.saveTermporalSulrvey},model:{value:(_vm.answerFour),callback:function ($$v) {_vm.answerFour=$$v},expression:"answerFour"}})],1)],1)])]),_c('hr'),_c('b-message',{attrs:{"type":"is-info"}},[_c('strong',[_vm._v("Rango de resultados")]),_c('br'),_vm._v(" "+_vm._s(_vm.lowResult)+" "),_c('br'),_vm._v(" Moderada a severa 5 – 10% BSA "),_c('br'),_vm._v(" Severa > 10% BSA ")]),_c('div',{staticClass:"block has-text-centered title"},[_vm._v(" Porcentaje de BSA/Resultado: "+_vm._s(_vm.score)+" ("+_vm._s(_vm.scoreText)+") "),(_vm.isIncompleted)?_c('span',[_vm._v("(Incompleto)")]):_vm._e()]),(!_vm.queryMode)?_c('div',{staticClass:"buttons is-centered"},[_c('b-button',{staticClass:"clear-button",attrs:{"icon-right":"block-helper"},on:{"click":_vm.clear}},[_vm._v("Limpiar")]),_c('b-button',{staticClass:"save-button",attrs:{"icon-right":"check-bold"},on:{"click":_vm.validate}},[_vm._v("Guardar")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }